"use client";

import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { UnControlledCheckbox } from "./UnControlledCheckbox";

export interface ControlledCheckboxProps {
  name: string;
  label?: string;
  className?: string;
  defaultChecked?: boolean;
  value?: string;
  onChange?: (value: boolean) => void;
}

export const ControlledCheckbox: React.FC<ControlledCheckboxProps> = ({
  name,
  label,
  className,
  value,
  onChange,
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange: handleChange, onBlur, value: checked, disabled } }) => (
        <UnControlledCheckbox
          name={name}
          label={label}
          disabled={disabled}
          className={className}
          value={value}
          checked={checked}
          onChange={(value) => {
            handleChange(value);
            onChange?.(value);
          }}
          onBlur={onBlur}
        />
      )}
    />
  );
};
